.pierwszeh4 {
    margin: 0px;
}

.drugie4 {
    margin-top: 0px;
}

.poczBut {
    margin-top: 30px;
}