.glowna {
    margin-top: 20px;
}

.obrazki {
    min-height: 270px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}

.ramka {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
}